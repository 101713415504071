import React, { useEffect, useState } from "react"
import MainLayout from "../components/layouts/main-layout.js"
import loadable from "@loadable/component"
import { graphql, Link } from "gatsby"
import HomeHero from "../components/hero/hero-operationalize.js"
import pMinDelay from "p-min-delay"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo.js"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Banner from "../components/hero/banner-hometeam.js"
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"
import { useContext } from "react"

import PartnerSwiper from "../components/swiper/swiper-partner.js"
import VideoSlider from "../components/video-slider/video-slider.js"

import ItConsulting from "../components/ai-driven.js"

import Testimonials2 from "../components/swiper/testimonial-swiper.js"

import UnlockEnterprise from "../components/unlock-enterprise.js"
import Cases from "../components/cases.js"

import CaseStudySection from "../components/header/header-casestudy-section.js"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const Operationalizepage = props => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    function f2(ev) {
      ev.preventDefault()
    }

    // A function that invokes a callback when the page has unsaved changes.
    // window.addEventListener("beforeunload", f2)

    // A function that invokes a callback when the page's unsaved changes are resolved.
    // window.removeEventListener("beforeunload", f2)

    // document.body.addEventListener("click", f2, {
    //   capture: false,
    //   passive: true,
    // })
    // document.body.addEventListener("touchstart", f2, {
    //   passive: true,
    //   capture: false,
    // })
  }, [])
  const { data } = props

  return (
    <MainLayout location={props.location} dataSrc={data.allSanityCaseStudy}>
      {/*<Hero />*/}
      <Seo
        title="Operationalize AI | Quandary Consulting Group"
        description="We help organizations plan, build, and optimize AI solutions in weeks. Get more time to innovate. Impress customers. Lead your industry."
      />

      <HomeHero />
      <PartnerSwiper />
      {/* <VideoSlider /> */}
      <div id="it-consult" className="it-consult-scroll" />
      <ItConsulting />
      <section
        className="faq-wrapper security-audit"
        style={{ padding: "1.5rem 0 4.5rem" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <h2 className="mt-0 text-center">
                Our Process for Operationalizing AI
              </h2>
              <Accordion defaultActiveKey="0" key="1">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="1" className="bg-transparent">
                    1. Discovery
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p>
                        Evaluate processes, data infrastructure, and business
                        goals to identify AI impact points.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="2">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="2" className="bg-transparent">
                    2. AI Strategy Design
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                        Create a custom roadmap for implementation that aligns
                        with business milestones and metrics.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="3">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="3" className="bg-transparent">
                    3. Data Prep
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p>
                        Clean, organize, and integrate data into AI-ready
                        formats to increase accuracy and usability.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="4">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="4" className="bg-transparent">
                    4. Model Development
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <p>
                        Build and test AI models based on unique data sets and
                        needs—driving actionable insights.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="5">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="5" className="bg-transparent">
                    5. Deployment
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <p>
                        Embed AI solutions into workflows and train teams to
                        maximize value.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="6">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="6" className="bg-transparent">
                    6. Optimization
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      <p>
                        Ongoing support and refinement to keep systems aligned
                        to needs and future-ready.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* <QuandaryValues /> */}
      <Testimonials2 />
      <CaseStudySection />
      <CaseComponent dataSrc={data.allSanityCaseStudy} />
      <Banner />
      <UnlockEnterprise />
      <section
        className="bg-case-study slogan-section"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center">
              <div>
                <h2 className="text-white">
                  Our Client Achieve Up to 30% More Efficiency and Cut Costs by
                  Over 25% with Operationalized AI
                </h2>
                {/* <p className="text-white">
                  Our 10-point security audit will show you how secure your
                  applications are. <br /> Protect your data. Protect your
                  investment. Protect your organization.
                </p> */}

                <Link
                  to="/contact/"
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                  style={{ marginTop: "auto" }}
                >
                  Let's Chat
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default Operationalizepage

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${
          isCurrentEventKey ? "text-primary-gradient" : ""
        }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

export const operationalizeQuery = graphql`
  query operationalizeQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
