import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import ScrollBtn from "../button/scroll-btn"
import useMediaQuery from "../../hooks/useMediaQuery"
import loadable from "@loadable/component"
import Swiper from "react-id-swiper"
import { Pagination, Navigation } from "swiper/js/swiper.esm"
import "./hero.css"
import "./homepage-hero.css"

const HeroLogo = loadable(() => import("./hero-logos"))
const ScrollBtn = loadable(() => import("../button/scroll-btn"))

const HeroOperationalize = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "new-hero.jpg" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1170
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  // const isDesktop = useMediaQuery("(min-width: 768px)")

  const params = {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 30,
    effect: "fade",
    breakpoints: {
      1024: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
    },
    // pagination: {
    //   el: ".swiper-pagination",
    //   clickable: true,
    // },
    containerClass: "swiper-container swiper-hero",
    nested: true,
    loop: "true",
    autoplay: {
      delay: 15000,
      disableOnInteraction: false,
    },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
  }

  const styles = {
    fontFamily: "Futura,sans-serif",
    marginBottom: "1.5rem",
    fontWeight: 500,
  }

  return (
    <>
      <div className="hero-banner relative pt-4 overflow-hidden">
        <div className="hero-overlay relative">
          <StaticImage
            placeholder="none"
            className="wave-img"
            src="./img/wave-white-right.png"
            alt="System Integration"
            layout="constrained"
            style={{ height: "fit-content", zIndex: 0 }}
            fetchpriority="low"
          />
        </div>
        <div className="container relative my-auto" style={{ zIndex: 1 }}>
          <div className="row pb-3 align-items-center">
            <div className="col-lg-7 col-xl-7 col-md-7">
              <div
                className="hero-custom-content-home review-content text-white"
                style={{ padding: "20px 0", marginTop: "5.3rem" }}
              >
                <h1
                  className="page-title text-left"
                  style={{
                    marginBottom: "0.5rem",
                    display: "inline",
                  }}
                >
                  {/* {item?.title} */}
                  Find and Capitalize on AI Opportunities Across Your
                  Organization
                </h1>
                <p className="mb-0 mt-2 text-left">
                  {/* {item.description} */}
                  We create custom AI roadmaps that identify and eliminate
                  operational weak points so teams can provide unthought-of
                  customer experiences.
                </p>
                {/* {item?.url && ( */}
                <div className="hero-button">
                  <Link
                    // to={`${item?.url}`}
                    to="/contact"
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center text-capitalize py-3"
                    style={{ marginTop: "auto" }}
                  >
                    {/* {item?.label} */}
                    LET'S CHAT
                  </Link>
                </div>
                {/* )} */}
              </div>
            </div>
            <div
              className="col-lg-5 col-md-5 pb-2 d-flex justify-content-end"
              style={{ position: "relative" }}
            >
              <StaticImage
                placeholder="none"
                className="rounded d-flex w-100"
                src="https://cdn.sanity.io/images/55lcecww/production/e6431bddc8f4ad80fe6f5fd6bd3d8909e73ba67d-1800x1800.png"
                alt="System Integration"
                layout="constrained"
                style={{ height: "fit-content", zIndex: 1 }}
                fetchpriority="low"
              />
              {/* <DesignElement />
              <DesignElement2 /> */}
            </div>
          </div>
        </div>
        <div className="swiperOverlay"></div>
      </div>
    </>
  )
}

export default HeroOperationalize
